import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NotifierModule } from "angular-notifier";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SystemModule } from './system/system.module';
import { TodoModule } from "./todo/todo.module";
import { AuthInterceptor } from "./auth-interceptor";
import { LuxonModule } from "luxon-angular";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SystemModule,
    NgbModule,
    FontAwesomeModule,
    HttpClientModule,
    TodoModule,
    FormsModule,
    ReactiveFormsModule,
    LuxonModule,
    NotifierModule.withConfig({
      position: {
        horizontal: { position: 'right'},
        vertical: { position: 'top'}
      }
    })
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
