import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SystemRepositoryService } from "../../../adapter/repositories/system-repository.service";
import { ProjectRepositoryService } from "../../../adapter/repositories/project-repository.service";
import { Project } from "../../../domain/project";

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

  project: Project|null;

  constructor(private _route: ActivatedRoute,
              private _projectRepo: ProjectRepositoryService) {
    this.project = null;
  }

  private ID_PARAM_KEY = "id";

  ngOnInit(): void {
    this._route.paramMap.subscribe(params => {
      if (params.has(this.ID_PARAM_KEY)) {
        const projectId = params.get(this.ID_PARAM_KEY) as string;
        this._projectRepo.getProject(projectId).subscribe(response => {
          this.project = response;
          this.getQueryTasks();
        });
      }
      else {
        this._projectRepo.getInboxProject().subscribe(response => {
          this.project = response;
          this.getQueryTasks();
        })
      }
    })
  }

  private getQueryTasks(): void {
    //TODO Query tasks
  }

}
