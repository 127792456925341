<div class="container">
  <div class="col-4 offset-2" style="margin-top:100px;">
    <h1>Log in</h1>
    <form [formGroup]="form" (ngSubmit)="onLogin()">
      <div class="form-floating mb-3">
        <input type="email" formControlName="email" id="uxEmail" class="form-control" [ngClass]="{'is-invalid':submitted && f['email'].errors}" placeholder="email">
        <label for="uxEmail">Email address</label>
        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
          <div *ngIf="f['email'].errors['required']">Email is required</div>
          <div *ngIf="f['email'].errors['email']">Email is invalid</div>
        </div>
      </div>
      <div class="form-floating mb-3">
        <input type="password" formControlName="password" id="uxPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" placeholder="password">
        <label for="uxPassword">Password</label>
        <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
          <div *ngIf="f['password'].errors['required']">Password is required</div>
          <div *ngIf="f['password'].errors['minlength']">
            Password must be at least 6 characters
          </div>
          <div *ngIf="f['password'].errors['maxlength']">
            Password must not exceed 40 characters
          </div>
        </div>
      </div>
      <div class="mb-3 d-grid">
        <button class="btn btn-primary" type="submit">Log in</button>
      </div>
      <div class="mb-3">
        <a href="#" class="link-secondary">Forgot your password?</a>
      </div>
      <hr>
      <div class="mb-3 text-center">
          Don't have an account? <a href="#">Sign up</a>
      </div>
    </form>
  </div>
</div>
