import { User } from "./user";

export class Project {

  static fromData(data: any): Project {
    return new Project(
      data.projectId,
      data.name,
      data.color,
      data.isArchived,
      User.fromData(data.createdBy),
      data.parent != null ? Project.fromData(data.parent) : null,
      data.children != null ? data.children.map((x: any) => Project.fromData(x)) : [],
      data.ordinal
    );
  }

  constructor(private readonly _projectId:string,
              private readonly _name:string,
              private readonly _color:string,
              private readonly _isArchived:boolean,
              private readonly _createdBy:User,
              private readonly _parent:Project|null,
              private readonly _children:Project[],
              private readonly _ordinal:number) {

    if (this._children === null) {
      this._children = [];
    }
  }


  get projectId(): string {
    return this._projectId;
  }

  get name(): string {
    return this._name;
  }

  get color(): string {
    return this._color;
  }

  get isArchived(): boolean {
    return this._isArchived;
  }

  get createdBy(): User {
    return this._createdBy;
  }


  get parent(): Project | null {
    return this._parent;
  }

  get children(): Project[] | null {
    return this._children;
  }

  get ordinal(): number {
    return this._ordinal;
  }

  toData(): any {
    return {
      projectId: this._projectId,
      name: this._name,
      color: this._color,
      isArchived: this._isArchived,
      createdBy: this._createdBy.toData(),
      parent: this._parent != null ? {projectId: this._parent.projectId} : null,
      ordinal: this._ordinal
    };
  }
}
