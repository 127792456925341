import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TodoComponent } from "./todo.component";
import { ProjectComponent } from "./project/project.component";

const routes: Routes = [
  {path: '',
    component: TodoComponent,
    children:[
      {path: 'inbox', component: ProjectComponent},
      {path: 'project/:id', component: ProjectComponent}
    ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TodoRoutingModule { }
