import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StatusComponent } from "./system/status/status.component";
import { LoginComponent } from "./system/login/login.component";

const routes: Routes = [
  {path: '', component: StatusComponent},
  {
    path: 'system',
    children: [
      {path: 'status', component: StatusComponent},
      {path: 'login', component: LoginComponent}
    ]
  },
  {
    path: 'todo',
    loadChildren: () => import('./todo/todo.module').then(m => m.TodoModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

