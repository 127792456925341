export class ApiStatus {


  constructor(private _version:string,
              private _status:string) { }

  get version(): string {
    return this._version;
  }

  get status(): string {
    return this._status;
  }
}
