export class User {

  static fromData(data: any): User {
    return new User(
      data.userId,
      data.emailAddress,
      data.fullName,
      data.isAdmin,
      data.inboxProjectId);
  }

  constructor(private readonly _userId:string,
              private readonly _emailAddress:string,
              private readonly _fullName:string,
              private readonly _isAdmin:boolean,
              private readonly _inboxProjectId:string) { }


  get userId(): string {
    return this._userId;
  }

  get emailAddress(): string {
    return this._emailAddress;
  }


  get fullName(): string {
    return this._fullName;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  get inboxProjectId(): string {
    return this._inboxProjectId;
  }

  toData(): any {
    return {
      userId: this._userId,
      emailAddress: this._emailAddress,
      fullName: this._fullName,
      isAdmin: this._isAdmin,
      inboxProjectId: this._inboxProjectId
    };
  }
}
