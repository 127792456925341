import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { SystemRepositoryService } from "../adapter/repositories/system-repository.service";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _systemRepository: SystemRepositoryService, private _router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipRequest = req.headers.has('skip');
    if (skipRequest) {
      const skipReq = req.clone({
        headers: req.headers.delete('skip')
      });
      return next.handle(skipReq);
    }
    let authorization = this._systemRepository.getAuthorization();
    if (authorization === null) {
      this._router.navigateByUrl('/system/login');
      throw new Error("Not authorized");
    }
    const authToken = authorization.accessToken;

    const authReq = req.clone({ setHeaders: { Authorization: authToken}});

    return next.handle(authReq);
  }

}
