import { NgModule } from '@angular/core';
import { TodoComponent } from "./todo.component";
import { TodoRoutingModule } from "./todo-routing.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgForOf, NgIf } from "@angular/common";
import { ProjectComponent } from "./project/project.component";

@NgModule({
  declarations: [
    TodoComponent,
    ProjectComponent
  ],
  imports: [
    TodoRoutingModule,
    FontAwesomeModule,
    NgIf,
    NgForOf
  ],
  providers: [],
  bootstrap: [TodoComponent]
})
export class TodoModule { }
