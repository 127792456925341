import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { catchError, map, Observable, throwError } from "rxjs";

import { ApiStatus } from "../../app/dtos/api-status";
import { environment } from "../../environments/environment";
import { DoAuthResponseDto } from "../../app/dtos/do-auth-response-dto";
import { User } from "../../domain/user";
import { ApiEnvelope } from "../../app/dtos/api-envelope";
import { Authorization } from "../../domain/authorization";
import { LocalService } from "../local-service";
import { DateTime } from "luxon";

const AUTHORIZATION_KEY = 'auth';

@Injectable({
  providedIn: 'root'
})
export class SystemRepositoryService {

  constructor(private _httpClient: HttpClient, private _local: LocalService) {
  }

  getStatus(): Observable<ApiStatus> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Cookie: 'cerebrum_Auth=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjZXJlYnJ1bV9iYWNrZW5kIiwiZXhwIjoyMjk2NjY2MjczLCJ1c2VySWQiOiIyMjJiMTY0NC05ZTQ5LTQ4MGQtODEwNS0yOGU0NTgwMzA4NjAifQ.HRr2ZL3UV-Tj4xNKF1GC6hNk52EKYMYHmMh5molBZnQ; Path=/; Expires=Sun, 11 Oct 2043 18:51:43 GMT;'
    //   })
    // }
    return this._httpClient.get<ApiStatus>(`${environment.apiURL}/status`).pipe(
      map((status) => {
        return new ApiStatus(status.version, status.status);
      })
    );
  }

  getAuthorization(): Authorization|null {
    const json = this._local.getData(AUTHORIZATION_KEY);
    if (!json) {
      return null;
    }
    const data = JSON.parse(json);
    const authorization = Authorization.fromData(data);
    console.log(`Token Valid Until: ${authorization.validUntil} Now is: ${DateTime.now()}`);
    if (authorization.validUntil < DateTime.now()) {
      this._local.removeData(AUTHORIZATION_KEY);
      return null;
    }
    return authorization;
  }

  doAuth(emailAddress: string, password: string): Observable<Authorization> {
    return this._httpClient.post<ApiEnvelope<DoAuthResponseDto>>(`${environment.apiURL}/doauth`,
      {
        emailAddress: emailAddress,
        password: password,
        isRemember: true
      },
      {headers:{skip:"true"}}).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error);
        } else if (error.status === 403) {
          return throwError(() => new Error("Login Failed"))
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.
          console.error(
            `Backend returned code ${error.status}, body was: `, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => new Error('Something bad happened; please try again later.'));
      }),
      map((result) => {
        let response = result.response;
        let authorization = Authorization.fromData(response);

        const data = authorization.toData();
        const json = JSON.stringify(data);
        this._local.saveData(AUTHORIZATION_KEY, json);
        return authorization;
      })
    )
  }

}
