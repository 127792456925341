import { Component, OnInit } from '@angular/core';
import { SystemRepositoryService } from "../../../adapter/repositories/system-repository.service";
import { ApiStatus } from "../../dtos/api-status";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {

  public environment;
  public apiStatus: ApiStatus|undefined;

  constructor(private _systemRepoService: SystemRepositoryService) {
    this.environment = environment
  }

  ngOnInit(): void {
    this._systemRepoService.getStatus().subscribe(response => {
      this.apiStatus = response;
    })
  }

}
