import { Component, OnInit } from "@angular/core";
import { SystemRepositoryService } from "../../../adapter/repositories/system-repository.service";

import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl('')
  });
  submitted = false;

  constructor(private _systemRepo: SystemRepositoryService,
              private _router: Router,
              private _notify: NotifierService,
              private _formBuilder: FormBuilder) { }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40)
          ]
        ]
      }
    )
  }

  onLogin(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this._systemRepo.doAuth(this.form.value.email, this.form.value.password).subscribe({
      next: (response) => {
        this.submitted = false;
        this._notify.notify('success', "Successfully logged in.");
        this._router.navigateByUrl('/todo/inbox');
      },
      error: (error) => {
        this.submitted = false;
        this._notify.notify('error', "There was an error logging in.")
      }
    });
  }
}
