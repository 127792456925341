<div class="row">
  <div class="col">
    <b>Production:</b>
  </div>
  <div class="col">
    <span *ngIf="environment.production">True</span>
    <span *ngIf="!environment.production">False</span>
  </div>
</div>
<div class="row">
  <div class="col">
    <b>Api Location:</b>
  </div>
  <div class="col">
    {{environment.apiURL}}
  </div>
</div>
<div class="row">
  <div class="col">
    <b>Status:</b>
  </div>
  <div class="col" *ngIf="!!apiStatus">
    {{apiStatus.status}}
  </div>
</div>
<div class="row">
  <div class="col">
    <b>Version:</b>
  </div>
  <div class="col" *ngIf="!!apiStatus">
    {{apiStatus.version}}
  </div>
</div>


