import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Project } from "../../domain/project";
import { map, Observable } from "rxjs";
import { ProjectDto } from "../../app/dtos/project-dto";
import { environment } from "../../environments/environment";
import { ApiEnvelope } from "../../app/dtos/api-envelope";

@Injectable({
  providedIn: 'root'
})
export class ProjectRepositoryService {
  constructor(private _httpClient: HttpClient) {}

  getProjects(): Observable<Project[]> {
    return this._httpClient.get<ApiEnvelope<ProjectDto[]>>(`${environment.apiURL}/api/v1/projects`).pipe(
      map((result) => {
        let response = result.response;
        return response.map((project) => {
          return Project.fromData(project);
        });
      })
    );
  }

  getProject(projectId: string): Observable<Project> {
    return this._httpClient.get<ApiEnvelope<ProjectDto>>(`${environment.apiURL}/api/v1/projects/${projectId}`).pipe(
      map(result => {
        let response = result.response;
        return Project.fromData(response);
      })
    );
  }

  getInboxProject(): Observable<Project> {
    return this._httpClient.get<ApiEnvelope<ProjectDto>>(`${environment.apiURL}/api/v1/projects/inbox`).pipe(
      map(result => {
        let response = result.response;
        return Project.fromData(response);
      })
    );
  }

}
