import { Component, OnInit } from '@angular/core';
import { faBars, faHouse, faMagnifyingGlass, faPlus, faBell, faInbox, faCalendar, faCalendarDays,
  faFilter, faCircle, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { NavigationEnd, Router } from "@angular/router";

import { ProjectRepositoryService } from "../../adapter/repositories/project-repository.service";
import { Project } from "../../domain/project";
import { SystemRepositoryService } from "../../adapter/repositories/system-repository.service";
import { Authorization } from "../../domain/authorization";

@Component({
  selector: 'app-todo',
  templateUrl: './todo.component.html',
  styleUrls: ['./todo.component.css']
})
export class TodoComponent implements OnInit {

  faBars = faBars;
  faHouse = faHouse;
  faMagnifyingGlass = faMagnifyingGlass;
  faPlus = faPlus;
  faBell = faBell;
  faInbox = faInbox;
  faCalendar = faCalendar
  faCalendarDays = faCalendarDays;
  faFilter = faFilter;
  faCircle = faCircle;
  faEllipsis = faEllipsis;
  projects: Project[]|undefined;
  private _authorization: Authorization|null;
  private _currentRoute: string;
  constructor(private _projectRepo: ProjectRepositoryService,
              private _systemRepo: SystemRepositoryService,
              private _router: Router) {
    this._authorization = null;
    this._currentRoute = "";
  }

  ngOnInit(): void {
    this._projectRepo.getProjects().subscribe(response => {
      this.projects = response;
    });
    this._authorization = this._systemRepo.getAuthorization();
    this._currentRoute = this._router.url;
  }

  onInboxClick(): void {
    this._router.navigate(["/todo/inbox"]).then(_ => {});
  }

  onProjectClick(projectId: string): void {
    this._router.navigate(["/todo/project", projectId]).then(_ => {});
  }

  getUserInitials(): string {
    if (this._authorization !== null) {
      const fullName = this._authorization.user.fullName;
      const nameSplit = fullName.split(" ");
      const initials = nameSplit.map(character => {
        return Array.from(character)[0];
      });
      return initials.join("");
    }
    return "ER";
  }

}
