import { User } from "./user";
import { DateTime } from "luxon";


export class Authorization {

  static fromData(data: any): Authorization {
    return new Authorization(
      data.accessToken,
      User.fromData(data.user),
      DateTime.fromISO(data.validUntil)
    );
  }

  constructor(private _accessToken:string,
              private _user:User,
              private _validUntil:DateTime) {

  }

  get accessToken(): string {
    return this._accessToken;
  }

  get user(): User {
    return this._user;
  }

  get validUntil(): DateTime {
    return this._validUntil;
  }

  toData(): any {
    return {
      accessToken: this._accessToken,
      user: this._user.toData(),
      validUntil: this._validUntil.toISO()
    };
  }
}
