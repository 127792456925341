<nav class="navbar navbar-dark sticky-top navbar-expand top-bar-dark">
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbar">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <button class="btn btn-dark">
            <fa-icon [icon]="faBars"></fa-icon>
          </button>
        </li>
        <li class="nav-item">
          <button class="btn btn-dark">
            <fa-icon [icon]="faHouse"></fa-icon>
          </button>
        </li>
        <li class="nav-item">
          <form class="d-flex" role="search">
            <div class="input-group">
              <span class="input-group-text">
                <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
              </span>
              <input type="text" class="form-control" aria-label="Search" placeholder="Search">
            </div>
          </form>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <button class="btn btn-dark">
            <fa-icon [icon]="faPlus"></fa-icon>
          </button>
        </li>
        <li class="nav-item">
          <button class="btn btn-dark">
            <fa-icon [icon]="faBell"></fa-icon>
          </button>
        </li>
        <li class="nav-item">
          <button class="btn btn-dark">
            {{getUserInitials()}}
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
<main>
  <div class="d-flex flex-column flex-shrink-0 p-3 text-white side-bar-dark" style="width:280px; background-color: ">
    <ul class="nav nav-pulls flex-column mb-auto">
      <li class="nav-item">
        <a class="btn btn-dark" routerLink="/todo/inbox" routerLinkActive="active">
          <fa-icon [icon]="faInbox"></fa-icon> Inbox
        </a>
      </li>
      <li class="nav-item">
        <button class="btn btn-dark">
          <fa-icon [icon]="faCalendar"></fa-icon> Today
        </button>
      </li>
      <li class="nav-item">
        <button class="btn btn-dark">
          <fa-icon [icon]="faCalendarDays"></fa-icon> Upcoming
        </button>
      </li>
      <li class="nav-item">
        <button class="btn btn-dark">
          <fa-icon [icon]="faFilter"></fa-icon> Filters & Labels
        </button>
      </li>
      <li class="nav-item mt-2">
        <div class="d-flex flex-row">
          <h6 class="me-auto">Projects</h6>
          <button class="btn btn-dark">
            <fa-icon [icon]="faPlus"></fa-icon>
          </button>
        </div>
      </li>
      <ng-template [ngIf]="!!projects">
        <ng-container *ngFor="let project of projects">
      <li class="nav-item d-grid" >
        <a class="btn btn-dark d-flex flex-row" style="align-items:center" [routerLink]="['/todo/project', project.projectId]" routerLinkActive="active">
          <fa-icon [icon]="faCircle" [style.color]="project.color" class="me-2 fa-xs"></fa-icon>
          {{project.name}}
          <fa-icon [icon]="faEllipsis" class="ms-auto"></fa-icon>
        </a>
      </li>
        <ng-template [ngIf]="!!project.children">
          <ng-container *ngFor="let child of project.children">
            <li class="nav-item d-grid">
              <button class="btn btn-dark ms-3 d-flex flex-row" style="align-items:center" [routerLink]="['/todo/project', child.projectId]" routerLinkActive="active">
                <fa-icon [icon]="faCircle" [style.color]="child.color" class="me-2 fa-xs"></fa-icon>
                {{child.name}}
                <fa-icon [icon]="faEllipsis" class="ms-auto"></fa-icon>
              </button>
            </li>
          </ng-container>
        </ng-template>
        </ng-container>
      </ng-template>
    </ul>
  </div>
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</main>

